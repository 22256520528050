* {
    /*font-family: Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif;*/
    /*font-family: 'Cabin', sans-serif;*/
    /*font-family: 'Quicksand', sans-serif;*/
}

h1 {
    font-size: 2em !important;
}

.link {
    text-decoration: none;
    color: white;
    margin-left: 30px;
    font-weight: bolder;
}


html, body {
    width:100%;
    height:100%;
    position: absolute;
}

.bg-nav-bar {
    border-bottom:1px solid #C4C4C7
}
.side-logo {
    width: 200px;
}
.side-nav {
    background-color: #374958;
    padding:10px;

}
.signin {
    background-color: #4B4B4B;
    width:100%;
    height:100%;
}

.col {
    padding:0 !important
}

.content {
    height: calc(100vh - 50px);
    border-top: 1px solid #DFDFE1;
    padding: 15px 35px !important;
    background-color: #FBFBFB;
    overflow: auto;
}

ul{
    list-style-type: none;
    margin-left:-30px;
    width:100%;
    font-size: 0.9em;
}

.navbar-list li{
    list-style-type: none;
    margin:0;

}

.navbar-list li{
    list-style-type: none;
    color: white;
    padding:10px 0;
    margin:0;
}

.navbar-list li a{
    color: white;
    text-decoration: none;
}
.navbar-list li:hover{
    text-decoration: underline
}
.navbar-list li a{
     color: white;
 }

.clients-table {
    margin-top: 20px;
}
.clients-table a{
    color: black !important;
}
.error {
    width: 800px !important;
    height: 300px;
    background-color: white;
    border-radius: 20px;
    padding:20px;
}
.request-access {
    width: 500px !important;
    height: 100px;
    background-color: white;
    border-radius: 20px;
    padding:20px;
}
a.nav-link {
    padding:5px;
}
.client-link {
    font-weight: bold;
    text-decoration: none;
}

a.client-link:hover {
    text-decoration: underline;
}
.env-badge {
    margin-right: 10px;
}
.spinner {
    margin-top:20px;
}

.form-card {
    margin: 10px 10px 0 0;
}
.form-group{
    margin:20px;
}
.card-header {
    font-weight: bold;
    font-size: 1.3em;
}

.slider {
    padding-left:10px;
}
.submit-row {
    padding-top:20px !important;
}

.timestamp {
    font-size: 0.8em;
    font-style: italic;
}
.default-data {
    color:#999999;
}

.help-content .col {
    margin: 0 20px 20px 0;
}

.form-pad-top {
    margin-top:20px !important;
}